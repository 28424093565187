import React, {useEffect} from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {WOW} from 'wowjs';
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Homepage from './pages/Homepage'
import About from './pages/About'
import Works from './pages/Works'


function App() {

  useEffect(() => {
      const wow = new WOW({
        offset: 100,
        mobile: false,
        live: true,
      });

      wow.init();
      wow.sync();
  }, []);

  return (
    <div>
      <Navbar />
       <BrowserRouter>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/about' element={<About />} />
          <Route path='/projects' element={<Works />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
