import {Helmet} from "react-helmet";
import play from '../assets/play.png'

import ourworks1 from '../assets/images/works/ourworks1.jpg'
import ourworks2 from '../assets/images/works/ourworks2.jpg'
import ourworks3 from '../assets/images/works/ourworks3.jpg'
import ourworks4 from '../assets/images/works/ourworks4.jpg'
import ourworks5 from '../assets/images/works/ourworks5.jpg'
import ourworks6 from '../assets/images/works/ourworks6.jpg'
import ourworks7 from '../assets/images/works/ourworks7.jpg'
import ourworks8 from '../assets/images/works/ourworks8.jpg'
import ourworks9 from '../assets/images/works/ourworks9.jpg'

import mechanic from '../assets/images/video/mechanic.jpg'
import birth from '../assets/images/video/birth.png'
import niger from '../assets/images/video/niger.jpg'
import waters from '../assets/images/video/waters.jpg'

const Works = () => {


	return (
		<>
			<Helmet>
                <title>Our works | Recreate Africa</title>
            </Helmet>
			<div className="-mt-[150px] pt-[150px] xl:px-20 lg:px-10 md:px-6 px-4 xl:pb-20 pb-10 bg-[#202225]">
				
				<div className="pb-20 text-center">
					<p className="text-[3em] font-bold text-white capitalize">our work</p>
				</div>
			    
		          
		          <div>
		          	<div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 xl:space-x-5 lg:space-x-5 md:space-x-5">
		          		<div className="xl:mb-10 lg:mb-[100px] md:mb-[100px] mb-10">
							<div className="aspect-ratio-16/9">
								<img src={ourworks5} className="object-cover w-full bg-no-repeat" alt="" />
							</div>
						</div>
						<div className="xl:mb-10 lg:mb-[100px] md:mb-[100px] mb-10">
							<div className="aspect-ratio-16/9">
								<img src={ourworks2} className="object-cover w-full bg-no-repeat" alt="" />
							</div>
						</div>
						<div className="xl:mb-10 lg:mb-[100px] md:mb-[100px] mb-10">
							<div className="aspect-ratio-16/9">
								<img src={ourworks6} className="object-cover w-full bg-no-repeat" alt="" />
							</div>
						</div>
						<div className="xl:mb-10 lg:mb-[100px] md:mb-[100px] mb-10">
							<div className="aspect-ratio-16/9">
								<img src={ourworks4} className="object-cover w-full bg-no-repeat" alt="" />
							</div>
						</div>
						<div className="xl:mb-10 lg:mb-[100px] md:mb-[100px] mb-10">
							<div className="aspect-ratio-16/9">
								<img src={ourworks1} className="object-cover w-full bg-no-repeat" alt="" />
							</div>
						</div>
						<div className="xl:mb-10 lg:mb-[100px] md:mb-[100px] mb-10">
							<div className="aspect-ratio-16/9">
								<img src={ourworks3} className="object-cover w-full bg-no-repeat" alt="" />
							</div>
						</div>
						<div className="xl:mb-10 lg:mb-[100px] md:mb-[100px] mb-10">
							<div className="aspect-ratio-16/9">
								<img src={ourworks7} className="object-cover w-full bg-no-repeat" alt="" />
							</div>
						</div>
						<div className="xl:mb-10 lg:mb-[100px] md:mb-[100px] mb-10">
							<div className="aspect-ratio-16/9">
								<img src={ourworks8} className="object-cover w-full bg-no-repeat" alt="" />
							</div>
						</div>
						<div className="xl:mb-10 lg:mb-[100px] md:mb-[100px] mb-10">
							<div className="aspect-ratio-16/9">
								<img src={ourworks9} className="object-cover w-full bg-no-repeat" alt="" />
							</div>
						</div>
		          	</div>

		          	<div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 xl:space-x-5 lg:space-x-5 md:space-x-5">
		          		<div className="xl:mb-10 lg:mb-[100px] md:mb-[100px] mb-10">
							<a href="https://www.youtube.com/watch?v=MMHwydXgvRs" target="_blank" className="group relative overflow-hidden">
								<div className="aspect-ratio-16/9">
									<img src={birth} className="object-cover w-full bg-no-repeat" alt="" />
								</div>
								<div className="absolute inset-0 bg-black bg-opacity-0 bg-opacity-50 transition duration-300 flex items-center justify-center">
									<img
							            src={play}
							            className="w-[60px] h-[60px] mx-auto my-auto opacity-0 transition duration-300 opacity-100"
		           						alt=""
							        />
								</div>
							</a>
							{/*<a href="">
								<p className="uppercase xl:text-lg text-base text-white mt-2 tracking-wider" style={{fontFamily: "Futura"}}>MY NAME: The Documentary</p>
							</a>*/}
						</div>
			          	<div className="mb-10">
							<a href="https://www.youtube.com/watch?v=PVbUaUtSQCs" className="group relative overflow-hidden">
								<div className="aspect-ratio-16/9">
									<img src={waters} className="w-full object-cover" alt="" />
								</div>
								<div className="absolute inset-0 bg-black bg-opacity-0 bg-opacity-50 transition duration-300 flex items-center justify-center">
									<img
								        src={play} // Replace with the actual path to your centered image
								        className="w-[60px] h-[60px] mx-auto my-auto opacity-0 transition duration-300 opacity-100"
		            					alt=""
							        />
								</div>
							</a>
							{/*<a href="">
								<p className="uppercase text-white text-lg mt-2 tracking-wider" style={{fontFamily: "Futura"}}>WATER'S EDGE - A UNICEF documentary film</p>
							</a>*/}
						</div>
		          		<div className="mb-10">
							<a href="https://www.youtube.com/watch?v=rKdAOQ09fi8" className="group relative overflow-hidden">
								<div className="aspect-ratio-16/9">
									<img src={niger} className="w-full object-cover" alt="" />
								</div>
								<div className="absolute inset-0 bg-black bg-opacity-0 bg-opacity-50 transition duration-300 flex items-center justify-center">
									<img
							            src={play} // Replace with the actual path to your centered image
							            className="w-[60px] h-[60px] mx-auto my-auto opacity-0 transition duration-300 opacity-100"
		           						alt=""
							        />
								</div>
							</a>
							{/*<a href="">
								<p className="uppercase text-white text-lg mt-2 tracking-wider" style={{fontFamily: "Futura"}}>WATER'S EDGE NIGER - Inspiring story of resilience and hope in Nigeria</p>
							</a>*/}
						</div>
						<div className="mb-10">
							<a href="https://www.youtube.com/watch?v=TBtEY8-0YT0" className="group relative overflow-hidden">
								<div className="aspect-ratio-16/9">
									<img src={mechanic} className="h-full w-full object-cover" alt="" />
								</div>
								<div className="absolute inset-0 bg-black bg-opacity-0 bg-opacity-50 transition duration-300 flex items-center justify-center">
									<img
								        src={play} // Replace with the actual path to your centered image
								        className="w-[60px] h-[60px] mx-auto my-auto opacity-0 transition duration-300 opacity-100"
		            					alt=""
							        />
								</div>
							</a>
							{/*<a href="">
								<p className="uppercase text-white text-lg mt-2 tracking-wider" style={{fontFamily: "Futura"}}>Local Area Mechanics (LAMs), Northwest Nigeria: A UNICEF documentary film</p>
							</a>*/}
						</div>
					</div>
		          </div>
			</div>
		</>
	)
}

export default Works